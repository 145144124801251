<template>
    <div>

        <div class=" qrcode" ref="qrCodeUrl" style="display: flex;justify-content: center;" id="purchaseBillsSerQrcode">
        </div>
        <div style="display: flex;justify-content: center;">请用手机扫描二维码,查看老人相关信息</div>
    </div>
</template>

<script>

//所需页面导入
import QRCode from 'qrcodejs2'
export default {
    name: 'YanglaoYrym',

    data() {
        return {
            qrUrl: "",
        };
    },
    props: {
        dataProps: {
            type: Object,
            default: ''
        }
    },
    mounted() {
        setTimeout(() => {
            this.creatQrCode();
        }, 500)

    },

    methods: {
        creatQrCode() {
            let text = this.dataProps.result.oldName
            this.$nextTick(() => {
                document.getElementById('purchaseBillsSerQrcode').innerHTML = ''
                new QRCode(this.$refs.qrCodeUrl, {
                    text: '',// 需要转换为二维码的内容
                    width: 300,
                    height: 300,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            })

        },
    }
};
</script>

<style lang="scss" scoped></style>